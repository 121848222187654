import React from 'react';
import { Alert, Button } from 'antd';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
export default function ErrorAlert() {
	const { hasError, setHasError, message, setMessage } = useGlobalContext();
	const resetMessage = () => {
		setHasError(false);
		setMessage(null);
	};
	return hasError ? (
		<Alert
			message="에러 발생"
			showIcon
			description={`원인 - ${message}`}
			type="error"
			action={
				<Button size="large" danger onClick={resetMessage}>
					확인
				</Button>
			}
		/>
	) : null;
}
