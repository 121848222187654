import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LINK from '../constants/link';
import ProtectedRoute from './ProtectedRoute';
import ErrorFallback from '../components/@common/Error/Page';

const Layout = lazy(() => import('components/@common/Layout'));
const Login = lazy(() => import('pages/Login'));
const Home = lazy(() => import('pages/Home'));
// const ReviewManagementDetail = lazy(() => import('pages/Home/detail'));
const UserManagement = lazy(() => import('pages/User/UserManagement'));
const UserManagementDetail = lazy(() =>
	import('pages/User/UserManagement/detail'),
);

const ReviewManagement = lazy(() => import('pages/Content/ReviewManagement'));
const ReviewManagementDetail = lazy(() =>
	import('pages/Content/ReviewManagement/detail'),
);

const VisitExperienceManagement = lazy(() => import('pages/Content/VisitExperienceManagement'));
const VisitExperienceManagementDetail = lazy(() =>
	import('pages/Content/VisitExperienceManagement/detail'),
);

const ViewtabInstagramManagement = lazy(() => import('pages/Content/ViewtabInstagramManagement'));
const ViewtabInstagramManagementDetail = lazy(() =>
	import('pages/Content/ViewtabInstagramManagement/detail'),
);

const WebsiteOutsourcingManagement = lazy(() => import('pages/Content/WebsiteOutsourcingManagement'));
const WebsiteOutsourcingManagementDetail = lazy(() =>
	import('pages/Content/WebsiteOutsourcingManagement/detail'),
);
const NoticeManagement = lazy(() => import('pages/Content/NoticeManagement'));
const NoticeManagementDetail = lazy(() => import('pages/Content/NoticeManagement/detail'));

const GuideManagement = lazy(() => import('pages/Content/GuideManagement'));
const GuideManagementDetail = lazy(() => import('pages/Content/GuideManagement/detail'));

const MenuActivationManagement = lazy(() => import('pages/Menu/ActivationManagement'));
const MenuAccessManagement = lazy(() => import('pages/Menu/AccessManagement'));

const ConstantManagement = lazy(() => import('pages/Constant/ConstantManagement'));

const TermManagement = lazy(() => import('pages/Term/TermManagement'));
const TermManagementDetail = lazy(() => import('pages/Term/TermManagement/detail'));

const PasswordManagement = lazy(() => import('pages/Home/password-management'));
const NotFoundPage = lazy(() => import('pages/Error/404'));
const router = createBrowserRouter([
	{
		path: '/',
		element: <Login />,
		index: true,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '',
		element: <ProtectedRoute />,
		children: [
			{
				path: `${LINK.HOME}`,
				element: <ReviewManagement />,
			},
			{
				path: `${LINK.PASSWORD_MANAGEMENT}`,
				element: <PasswordManagement />,
			},
			{
				path: `${LINK.CONTENT.REVIEW}`,
				element: <ReviewManagement />,
			},
			{
				path: `${LINK.CONTENT.REVIEW_DETAIL}`,
				element: <ReviewManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.REVIEW_DETAIL}/:id`,
				element: <ReviewManagementDetail />,
			},
			{
				path: `${LINK.USER}`,
				element: <UserManagement />,
			},
			{
				path: `${LINK.USER_DETAIL}`,
				element: <UserManagementDetail />,
			},
			{
				path: `${LINK.USER_DETAIL}/:id`,
				element: <UserManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.TEAM}`,
				element: <VisitExperienceManagement />,
			},
			{
				path: `${LINK.CONTENT.TEAM_DETAIL}`,
				element: <VisitExperienceManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.TEAM_DETAIL}/:id`,
				element: <VisitExperienceManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.VIEW}`,
				element: <ViewtabInstagramManagement />,
			},
			{
				path: `${LINK.CONTENT.VIEW_DETAIL}`,
				element: <ViewtabInstagramManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.VIEW_DETAIL}/:id`,
				element: <ViewtabInstagramManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.WEB}`,
				element: <WebsiteOutsourcingManagement />,
			},
			{
				path: `${LINK.CONTENT.WEB_DETAIL}`,
				element: <WebsiteOutsourcingManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.WEB_DETAIL}/:id`,
				element: <WebsiteOutsourcingManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.NOTICE}`,
				element: <NoticeManagement />,
			},
			{
				path: `${LINK.CONTENT.NOTICE_DETAIL}`,
				element: <NoticeManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.NOTICE_DETAIL}/:id`,
				element: <NoticeManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.GUIDE}`,
				element: <GuideManagement />,
			},
			{
				path: `${LINK.CONTENT.GUIDE_DETAIL}`,
				element: <GuideManagementDetail />,
			},
			{
				path: `${LINK.CONTENT.GUIDE_DETAIL}/:id`,
				element: <GuideManagementDetail />,
			},
			{
				path: `${LINK.MENU.ACTIVATE}`,
				element: <MenuActivationManagement />,
			},
			{
				path: `${LINK.MENU.ACCESS}`,
				element: <MenuAccessManagement />,
			},
			{
				path: `${LINK.CONSTANT.CODE}`,
				element: <ConstantManagement />,
			},
			{
				path: `${LINK.TERMS}`,
				element: <TermManagement />,
			},
			{
				path: `${LINK.TERMS_DETAIL}`,
				element: <TermManagementDetail />,
			},
			{
				path: `${LINK.TERMS_DETAIL}/:id`,
				element: <TermManagementDetail />,
			}
		],
	},
	{
		path: "*",
		element: <ErrorFallback />,
        status: 404,
        exact: true,
	}
]);

export default router;
