import { RecoilRoot } from 'recoil';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import theme from 'styles/theme';
import GlobalStyles from 'styles/global';
import router from 'routes/routing';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import Spinner from './components/@common/Spin';
import FallbackSpinner from './components/@common/Spin/FallbackSpinner';
import ErrorFallback from 'components/@common/Error/Page';
import ErrorAlert from './components/@common/Error/Alert/ErrorAlert';
import { GlobalProvider } from './contexts/GlobalContext';
import SuccessAlert from './components/@common/Success/Alert/SuccessAlert';
function App() {
	dayjs.locale('ko');
	return (
		<RecoilRoot>
			<GlobalProvider>
				<Spinner />
				<ErrorAlert />
				<SuccessAlert />
				<ErrorBoundary fallback={<ErrorFallback />}>
					<Suspense fallback={<FallbackSpinner />}>
						<ThemeProvider theme={theme}>
							<GlobalStyles />
							<RouterProvider router={router} />
						</ThemeProvider>
					</Suspense>
				</ErrorBoundary>
			</GlobalProvider>
		</RecoilRoot>
	);
}

export default App;
