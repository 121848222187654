import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';
export default function FallbackSpinner() {
	const [isLoading] = useState(true);
	return (
		<Space>
			<Spin
				spinning={isLoading}
				indicator={
					<LoadingOutlined
						style={{
							fontSize: 48,
						}}
						spin
					/>
				}
				fullscreen
			/>
		</Space>
	);
}
