import Cookies from 'js-cookie';
export class Jwt {
    static getUserInfo() {
        if (!Jwt.isAuthenticated()) return null;
        return Jwt.parseJwt(Jwt.getAccessToken());
    }

    static isMasterUser() {
        const userInfo = Jwt.getUserInfo();
        if (['SUPER_ADMIN', 'MAINTAINER'].includes(userInfo && userInfo.userType)) return true;
        return false;
    }

    static setToken(token) {
        Jwt.setAccessToken(token.accessToken);
        Jwt.setRefreshToken(token.refreshToken);
    }

    static setAccessToken(accessToken) {
        const expiryDate = Jwt.getExpiryDate(accessToken); // 만료 날짜를 계산
        Cookies.set('accessToken', accessToken, { expires: expiryDate });
    }

    static setRefreshToken(refreshToken) {
        const expiryDate = Jwt.getExpiryDate(refreshToken); // 만료 날짜를 계산
        Cookies.set('refreshToken', refreshToken, { expires: expiryDate });
    }

    static getAccessToken() {
        return Cookies.get('accessToken');;
    }

    static getRefreshToken() {
        return Cookies.get('refreshToken');
    }

    static clearTokens() {
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
    }

    static async isAuthenticated() {
        const accessToken = Cookies.get('accessToken');
        let refreshToken = Cookies.get('refreshToken');
        if (Jwt.isRefreshTokenExpired(refreshToken)) refreshToken = null;
        // token이 모두 없으면 로그인 필요
        if (!accessToken && !refreshToken) return false;
        // refreshToken만 존재할 경우 accessToken을 재발행
        if (!accessToken) {
            try {
                const response = await Jwt.refreshAccessToken(refreshToken);
                if (response.statusCode === 201) {
                    const { accessToken, refreshToken } = response.data;
                    Jwt.setToken({ accessToken, refreshToken });
                } else {
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        // 접근가능유저 권한체크
        const userInfo = Jwt.parseJwt(Jwt.getAccessToken());
        return userInfo && ['ADMIN', 'MAINTAINER', 'SUPER_ADMIN', 'EMPLOYEE'].includes(userInfo.userType);
    }

    static isRefreshTokenExpired() {
        const tokenPayload = Jwt.parseJwt(Jwt.getRefreshToken());
        if (!tokenPayload) return true;
        const expiryDate = new Date(0);
        expiryDate.setUTCSeconds(tokenPayload.exp);
        const now = new Date();
        const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
        return expiryDate < utcNow;
    }

    static parseJwt(token) {
        if (!token) return null;
        const base64Url = token?.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    static getExpiryDate(token) {
        const tokenPayload = Jwt.parseJwt(token);
        const expiryDate = new Date(0);
        expiryDate.setUTCSeconds(tokenPayload.exp);
        return expiryDate;
    }

    static async refreshAccessToken() {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        return await fetch(`${apiBaseUrl}/auth/renew-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken: Jwt.getRefreshToken() }),
          })
           .then((response) => response.json())
           .then((data) => {
              return data;
            });
    }
}