import { Alert, Button } from "antd";
import { useGlobalContext } from "../../../../contexts/GlobalContext";


export default function SuccessAlert() {
    const { isSuccessful, setIsSuccessful, message, setMessage } = useGlobalContext();
	const resetMessage = () => {
		setIsSuccessful(false);
		setMessage(null);
	};
    return isSuccessful ? (
			<Alert
				message={message ?? '안내'}
				description="요청하신 작업이 성공적으로 완료되었습니다"
				type="success"
				showIcon
				action={
					<Button size="large" onClick={resetMessage}>
						확인
					</Button>
				}
				style={{
					position: 'absolute',
					top: '0',
					left: '50%',
					transform: 'translateX(-50%)',
				}}
			/>
		) : null;
}