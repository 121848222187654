const PALETTE = {
	blue: '#448EF8',
	black: '#111111',
	white: '#FFFFFF',
	hover: '#F6F6F6',
	navy: '#003E53',
};

const FONT_SIZE = {
	xxs: '6px',
	xs: '8px',
	s: '10px',
	ms: '12px',
	m: '14px',
	ml: '16px',
	l: '18px',
	xl: '20px',
	xxl: '22px',
	j: '24px',
	xj: '32px',
	xxj: '36px',
};

const theme = {
	PALETTE,
	FONT_SIZE,
};

export default theme;
