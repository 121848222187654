import React, { createContext, useState, useContext, useEffect } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isSuccessful, setIsSuccessful] = useState(false);
	const [message, setMessage] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [searchOption, setSearchOption] = useState({});
	const toggleModal = () => {
		setShowModal(showModal => (showModal = !showModal));
	};
	const handleChangeSearchOption = (e, page) => {
		if (!e) return;
		const { name, value } = e.target;
		if (!name) return;
		setSearchOption((prev) => ({ ...prev, [`${page}`]: { ...prev[page], [name]: value} }));
	};
	useEffect(() => {
		let timer;
		if (isSuccessful) timer = setTimeout(() => setIsSuccessful(false), 2000);
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [isSuccessful]);
	/* useEffect(() => {
		let timer;
		if (hasError) timer = setTimeout(() => setHasError(false), 2000);
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [hasError]) */

	return (
		<GlobalContext.Provider
			value={{
				isLoading,
				setIsLoading,
				isSuccessful,
				setIsSuccessful,
				hasError,
				setHasError,
				message,
				setMessage,
				showModal,
				setShowModal,
				toggleModal,
				searchOption,
				setSearchOption,
				handleChangeSearchOption,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => useContext(GlobalContext);
