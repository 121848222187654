import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';
import { useGlobalContext } from '../../../contexts/GlobalContext';
export default function Spinner() {
	const { isLoading } = useGlobalContext();
	return isLoading ? (
		<Space>
			<Spin
				spinning={isLoading}
				indicator={
					<LoadingOutlined
						style={{
							fontSize: 48,
						}}
						spin
					/>
				}
				fullscreen
			/>
		</Space>
	) : null;
}
