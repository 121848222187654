import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
    ${reset}

    @font-face {
      font-family: 'Pretendard';
      src: url('/assets/fonts/Pretendard-Light.otf') format('opentype');
      font-weight: 300;
    }

    @font-face {
      font-family: 'Pretendard';
      src: url('/assets/fonts/Pretendard-Regular.otf') format('opentype');
      font-weight: 400;
    }

    @font-face {
      font-family: 'Pretendard';
      src: url('/assets/fonts/Pretendard-Medium.otf') format('opentype');
      font-weight: 500;
    }

    @font-face {
      font-family: 'Pretendard';
      src: url('/assets/fonts/Pretendard-SemiBold.otf') format('opentype');
      font-weight: 600;
    }    

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: sans-serif;
      font-family: 'Pretendard' !important;
      font-weight: 400;
    }
    
    body {
      font-family: 'Pretendard', sans-serif;
      color: ${({ theme }) => theme.PALETTE.black};
    }

    button {
      border: none;
      cursor: pointer;
    }

    ul > li {
      list-style: none;
    }

    ::-webkit-scrollbar {
      display: none;
    }
    body {
      height: 100%;
      overflow-y: auto;
      background-color: #f8f8f8;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e6e6e6;
    }

    .margin-right {
      margin-right: 16px;
    }
    .ant-breadcrumb {
    & > span {
      &:last-child {
        color: #999;
        font-weight: normal;
      }
    }
  }

  .ant-breadcrumb-link {
    .anticon + span {
      margin-left: 4px;
    }
  }

  .ant-table {
    .ant-table-thead > tr > th {
      text-align: center;
    }

    .ant-table-tbody > tr > td {
      text-align: center;
    }

    &.ant-table-small {
      .ant-table-thead > tr > th {
        background: #f7f7f7;
      }

      .ant-table-body > table {
        padding: 0;
      }
    }
  }

  .ant-table-pagination {
    float: none !important;
    display: table;
    margin: 16px auto !important;
  }

  .ant-popover-inner {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
  }

  .ant-form-item-control {
    vertical-align: middle;
  }

  .ant-modal-mask {
    background-color: rgba(55, 55, 55, 0.2);
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item {
    padding: 12px 16px !important;
  }

  a:focus {
    text-decoration: none;
  }

  .ant-table-layout-fixed table {
    table-layout: auto;
  }

  @media (min-width: 1600px) {
    .ant-col-xl-48 {
      width: 20%;
    }

    .ant-col-xl-96 {
      width: 40%;
    }
  }
  @media (max-width: 767px) {
    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-options,
    .ant-pagination-prev {
      margin-bottom: 8px;
    }

    .ant-card {
      .ant-card-head {
        padding: 0 12px;
      }

      .ant-card-body {
        padding: 12px;
      }
    }
  }
`;

export default GlobalStyles;
