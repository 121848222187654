const LINK = {
	LOGIN: '/',
	HOME: '/main',
	PASSWORD_MANAGEMENT: '/password-management',

	// 사실상 이게 홈
	REVIEW: '/content/review-management',
	REVIEW_DETAIL: '/content/review-management/detail',

	USER: '/user/user-management',
	USER_DETAIL: '/user/user-management/detail',

	CONTENT: {
		REVIEW: '/content/review-management',
		REVIEW_DETAIL: '/content/review-management/detail',
		TEAM: '/content/visit-experience-management',
		TEAM_DETAIL: '/content/visit-experience-management/detail',
		VIEW: '/content/view-instagram-management',
		VIEW_DETAIL: '/content/view-instagram-management/detail',
		WEB: '/content/website-outsourcing-management',
		WEB_DETAIL: '/content/website-outsourcing-management/detail',
		NOTICE: '/content/notice-management',
		NOTICE_DETAIL: '/content/notice-management/detail',
		GUIDE: '/content/guide-management',
		GUIDE_DETAIL: '/content/guide-management/detail',
	},

	MENU: {
		ACTIVATE: '/menu/activation-management',
		ACCESS: '/menu/access-management',
	},

	CONSTANT: {
		CODE: '/constant/constant-management',
	},

	TERMS: '/term/term-management',
	TERMS_DETAIL: '/term/term-management/detail',
};

export default LINK;
