import { lazy, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Jwt } from '../utils/Jwt';
import { useGlobalContext } from '../contexts/GlobalContext';
import Spinner from '../components/@common/Spin';

const Layout = lazy(() => import('components/@common/Layout'));
export default function ProtectedRoute() {
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const redirectionPath = location?.pathname ?? null;
    const { setIsLoading } = useGlobalContext();
    useEffect(() => {
      const checkAuth = async () => {
        setIsLoading(true);
        const authStatus = await Jwt.isAuthenticated();
        setIsAuthenticated(authStatus);
        setIsLoading(false);
      };
      checkAuth();
    }, [location]);
    if (isAuthenticated === null) return <Spinner />;
    if (!isAuthenticated) return <Navigate to={redirectionPath ? `/login?redirection=${redirectionPath}` : `/`} replace />;
    return <Layout />;
};